<template>
  <nav class="button-tray">
    <slot name="header" />

    <div v-if="$slots.error" class="button-tray__errors">
      <slot name="error" />
    </div>

    <div class="button-tray__buttons">
      <slot />
    </div>
  </nav>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.button-tray {
  bottom: 8px;
  left: 8px;
  right: 8px;
  background-color: $white;
  border-radius: $radius;
  box-shadow: $shadow;
  padding: var(--button-tray-gutter, 8px);
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: calc(
    env(safe-area-inset-bottom) + var(--button-tray-gutter, 8px)
  );
  max-width: rem(400);
  z-index: 12;
  width: calc(100% - 8px - 8px); // 100% - left - right

  &--restricted {
    position: sticky;

    @include viewport('md') {
      margin-right: 0;
      min-width: rem(300);
    }
  }

  &--results-kiosk {
    margin-right: 0;
    pointer-events: all;
    position: static;
  }
}

.button-tray__errors > .errorText {
  margin-top: 0;
  margin-bottom: rem(16);
}

.button-tray__buttons {
  display: flex;
  flex-direction: row;
  gap: rem(8);
  flex-wrap: wrap;

  @include viewport('lg') {
    gap: rem(16);
  }

  &:deep(> button, > .os-button) {
    flex: 1 1 auto;
    min-width: fit-content;
    padding: 0 rem(4);
  }
}
</style>
